@import 'scss-imports/splitview';

/* Universal Overrides for Egret */

.hidden {
  display: none;
}

.invisible {
  opacity: 0;
}

/* Navigation shorter and fixed to Top */
div.mat-toolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 64px;
  padding: 0 16px;
  width: 100%;
}

.xs .rightside-content-hold {
  padding: 0 !important;

  @media (max-width: $breakpoint-tablet) {
    padding-bottom: 96px !important;
  }
}

/* Tabbed navigation is fixed too */
.tab-header-fixed .mat-mdc-tab-header {
  background: #efefef;
  flex-shrink: 0;
  overflow: hidden;
  position: fixed !important;
  top: 104px;
  width: 100%;
  z-index: 2;
}
/*--- Data table ---*/
.datatable-checkbox input[type='checkbox'] {
  margin-left: -3px;
  margin-top: -10px;
}

.mat-mdc-tab-body-wrapper {
  padding: 16px;
}

/* Cards & Tables */
.mat-mdc-card-table {
  padding: 0 !important;
}

.mat-mdc-card-table .ngx-datatable {
  margin: 0 !important;
}

ngx-datatable .mat-box-shadow {
  box-shadow: none;
}

.mat-card-title-text {
  font-size: 24px;
  margin: 16px;
}

/* mat-sidenav */
mat-sidenav-content.fn-maincontent {
  transform: translate3d(0, 0, 0);
}

body.collapsed-menu mat-sidenav.mat-sidenav-closed mat-sidenav-content.fn-maincontent {
  margin-left: 48px !important;
  margin-right: 0 !important;
  transform: translate3d(0, 0, 0);
}

body.collapsed-menu mat-sidenav-container.mat-drawer-opened mat-sidenav-content.fn-maincontent {
  margin-left: 48px !important;
  margin-right: 0 !important;
  transform: translate3d(0, 0, 0);
}

.mat-sidenav.mat-sidenav-opened,
.mat-sidenav.mat-sidenav-opening {
  transform: translate3d(0, 0, 0);
}

/* side menu*/
.mat-nav-list .mat-mdc-list-item {
  display: block;
  height: auto !important;
}

.drag-handle {
  cursor: move;
  position: relative;

  .grip {
    color: #ccc;
    content: '....';
    cursor: move;
    display: inline-block;
    font-family: sans-serif;
    font-size: 12px;
    height: 20px;
    letter-spacing: 2px;
    line-height: 5px;
    margin-right: 0.3em;
    margin-top: -0.9em;
    overflow: hidden;
    padding: 3px 4px;
    pointer-events: none;
    text-shadow: 1px 0 1px black;
    vertical-align: middle;
    white-space: normal;
    width: 10px;

    &::after {
      content: '.. .. .. ..';
    }
  }

  > .cdk-drag-handle {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }
}
