/* CSS Vars */
:root {
  --fn-dropshadow: 0 1px 2px rgba(0, 0, 0, 0);
  --contrast-normal: var(--bg2);
  --contrast-darker: hsl(0, 0%, 10.7%); // default theme fallback
  --contrast-darkest: hsl(0, 0%, 5.7%); // default theme fallback
  --contrast-lighter: hsl(0, 0%, 20.7%); // default theme fallback
  --contrast-lightest: hsl(0, 0%, 25.7%); // default theme fallback
  --light-theme-lines: var(--contrast-darkest);
  --dark-theme-lines: var(--contrast-lighter);
  --disabled-opacity: 0.5;
  --lines: var(--dark-theme-lines);
  --error: #f44336;
  --sidenav-width: 240px;
  --btn-default-bg: var(--alt-bg2);
  --hover-bg: var(--lines) !important;
  --primary-lighter: var(--primary);
  --font-family-header: 'Titillium Web', sans-serif;
  --font-family-body: 'IBM Plex Sans', sans-serif;
  --mdc-dialog-subhead-color: var(--fg1);
  --mdc-dialog-supporting-text-color: var(--fg2);
  --mdc-dialog-supporting-text-font: 'IBM Plex Sans', 'Roboto' , 'Helvetica Neue' ,sans-serif;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-subhead-size: 1.42rem;
  --mdc-dialog-subhead-font: 'Roboto', 'Helvetica Neue', sans-serif;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-supporting-text-line-height: 1.65;
  --mdc-dialog-subhead-line-height: 1.65;
  --mdc-dialog-supporting-text-tracking: 0;
  --mdc-dialog-container-shape: 0;
  --mdc-dialog-subhead-tracking: 0;
  --mdc-shape-medium: 4px;
  --mdc-theme-primary: var(--primary);
  --mdc-switch-selected-handle-color: var(--primary);
  --mdc-switch-selected-track-color: var(--primary);
  --mdc-switch-selected-hover-handle-color: var(--primary);
  --mdc-switch-selected-hover-track-color: var(--primary);
  --mdc-switch-selected-focus-handle-color: var(--primary);
  --mdc-switch-selected-focus-track-color: var(--primary);
  --mdc-switch-selected-pressed-handle-color: var(--primary);
  --mdc-switch-selected-pressed-track-color: var(--primary);
  --primary-txt: #fff;
  --mdc-circular-progress-active-indicator-color: var(--primary);
  --mat-mdc-radio-checked-ripple-color: var(--primary);
  --mat-mdc-radio-ripple-color: var(--primary);
  --mdc-radio-selected-icon-color: var(--primary);
  --mdc-radio-unselected-icon-color: var(--primary);
  --mdc-radio-selected-hover-icon-color: var(--primary);
  --mdc-radio-selected-pressed-icon-color: var(--primary);
  --mdc-radio-unselected-hover-icon-color: var(--primary);
  --mdc-radio-unselected-pressed-icon-color: var(--primary);
  --mdc-radio-unselected-focus-icon-color: var(--primary);
  --mdc-radio-selected-focus-icon-color: var(--primary);
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #000000de;
  --mdc-chip-label-text-color: #000000de;
  --mdc-chip-container-height: 23px;
  --mdc-checkbox-selected-icon-color: var(--primary);
  --mdc-checkbox-unselected-icon-color: var(--primary);
  --mdc-checkbox-selected-hover-icon-color: var(--primary);
  --mdc-checkbox-unselected-hover-icon-color: var(--primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary);
  --mdc-checkbox-unselected-pressed-icon-color: var(--primary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--primary);
  --mdc-checkbox-disabled-selected-icon-color: #9c9c9c;
  --mdc-checkbox-disabled-unselected-icon-color: #9c9c9c;
  --mdc-snackbar-container-color: var(--bg1);
  --mdc-snackbar-supporting-text-color: var(--primary-txt);
  --mdc-snackbar-supporting-text-font: var(--mdc-dialog-supporting-text-font);
  --mdc-snackbar-supporting-text-size: 1rem;
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-selected-container-color: var(--bg2);
  --mat-mdc-slider-hover-ripple-color: rgba(0, 149, 213, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 149, 213, 0.2);
  --mat-mdc-button-persistent-ripple-color: rgba(0, 0, 0, 0.2);
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.2);
  --mdc-checkbox-state-layer-size: 40px;
  --mat-tab-header-inactive-label-text-color: var(--fg2);
  --mat-tab-header-inactive-hover-label-text-color: var(--fg1);
  --mat-tab-header-inactive-focus-label-text-color: var(--fg1);
}
